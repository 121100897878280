<script>
//import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
//import flatPickr from "vue-flatpickr-component";
//import "flatpickr/dist/flatpickr.css";

import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";

import { required, email, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { mapState } from "vuex";
import axios from 'axios';
import Paginate from 'vuejs-paginate-next';

import {
  authMethods,
  authFackMethods,
  notificationMethods,
} from "@/state/helpers";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  page: {
    title: "Setting",
    meta: [{
      name: "description",
      content: appConfig.description
    }],
  },
  data() {
    return {
      title: "Setting",
      items: [{
        text: "Velzon",
        href: "/",
      },
      {
        text: "Setting",
        active: true,
      },
      ],
      value: ['javascript'],
      file: "",
      imgsrc: "",
      date: null,

      submitted: false,
      regError: null,
      regSuccess: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
      userserror: false,
      userserrormsg: '',
      usersok: false,
      is_admin: false,
      usersokmsg: '',
      userSearchTerm: '',
      currentpage: 0,
      totalpage: 0,
      totalrows: 0,
      payment_currentpage: 0,
      payment_totalpage: 0,
      payment_totalrows: 0,
      updateuser_id: 0,
      usertype: 'user',
      userstatus: '',
      userslist: [],
      paymentslist: [],
      db_packagename: 'Package 1',
      db_writer_remaining_words: 0,
      db_writer_remaining_charactors: 0,
      db_writer_remaining_credits: 0,
      db_narrations: 0,
      db_pdfupload: 0,
      paymentsSearchTerm: '',
      db_validity: '',
      db_Transcription: 0,
      db_ImageGeneration: 0,
      user: {
        name: "",
        useremail: ""
      },
      oldpasswordInput: '',
      newpasswordInput: '',
      confirmpasswordInput: '',
      passerror: false,
      passok: false,
      passerrormsg: '',
      passokmsg: '',

    };
  },
  validations: {
    user: {
      name: {
        required: helpers.withMessage("User name is required", required),
      },

      useremail: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Please enter valid email", email),
      }
    },
  },
  computed: {
    ...mapState("authfack", ["status"]),
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  components: {
    Layout,
    paginate: Paginate,
    //Multiselect,
    //flatPickr
  }, mounted: function () {
    this.getUserData();
   // this.getpaymentData();
  },
  methods: {
    clickCallback: function (pageNum) {
      // alert("pageNum==" + pageNum);
      this.currentpage = parseInt(pageNum) - 1;
      this.getUserData();

    },
    async getUserData() {
      //var userid = localStorage.getItem('userid')
      this.usertype = localStorage.getItem('usertype')
      this.user.name = localStorage.getItem('username');
      this.user.useremail = localStorage.getItem('useremail');
      //alert(this.usertype);
      if (this.usertype == 'Admin') {
        this.is_admin = true;
        //alert(parseInt(this.currentpage)+ "======" + this.userSearchTerm);
        var result = await axios.post(appConfig.api_url + 'users', {
          filter: '' + this.userSearchTerm,
          page: this.currentpage
        })

        var users = result.data.data.user_res;
        this.totalpage = result.data.totalpages;
        this.totalrows = result.data.totalrows;
        //alert(JSON.stringify(result.data));
        console.log(JSON.stringify(users));
        this.userslist = users;


        // if (result.data.status == 'success') {

        // } else {
        // }
      }

      result = await axios.post(appConfig.api_url + 'getsubscription', {
        user_id: localStorage.getItem('userid')
      })
      //  alert(JSON.stringify(result.data.data))
      var dt = result.data.data;

      this.db_packagename = dt.db_packagename
      this.db_writer_remaining_words = dt.db_writer_remaining_words
      this.db_writer_remaining_charactors = dt.db_writer_remaining_charactors
      this.db_writer_remaining_credits = dt.db_writer_remaining_credits
      this.db_narrations = dt.db_narrations
      this.db_pdfupload = dt.db_pdfupload
      this.db_Transcription = dt.db_Transcription
      this.db_ImageGeneration = dt.db_ImageGeneration
      this.db_validity = dt.db_validity;
    },
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    onselect() {
      const file = this.$refs.file.files[0];
      this.file = file;
      this.imgsrc = URL.createObjectURL(file);
      //alert("on select ="+this.file);
    },
    async updateuserprofile() {
      this.submitted = true;



      // stop here if form is invalid
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        this.tryingToRegister = true;
        // Reset the regError if it existed.

        // {
        //   Name: this.user.name,
        //   Email: this.user.useremail,
        //   Id: localStorage.getItem('userid'),
        //   City: this.user.city,
        //   Country: this.user.country,
        //   mobile: this.user.mobile,
        //   zipcode: this.user.zipcode,
        //   description: this.user.description,
        //   file: this.file
        // }

        this.regError = null;
        let formData = new FormData();

        formData.append("Name", this.user.name);
        formData.append("Email", this.user.useremail);
        formData.append("Id", localStorage.getItem('userid'));
        // formData.append("City", this.user.city);
        // formData.append("Country", this.user.country);
        // formData.append("mobile", this.user.mobile);
        // formData.append("zipcode", this.user.zipcode);
        // formData.append("description", this.user.description);
        // alert("====called == " + this.file);
        // formData.append("file", this.file);
        // alert("====called == " + this.file);


        // const config = {
        //   header: {
        //     "Content-Type": "multipasrt/form-data",
        //   },
        // };
        //  alert(this.user.name)
        const result = await axios.post(appConfig.api_url + 'updateuserprofile', {
          Id: localStorage.getItem('userid'),
          Name: this.user.name,
          Email: this.user.useremail
        });

        //   alert(JSON.stringify(result.data))

        if (result.data.status == 'errors') {
          this.tryingToRegister = false;
          this.regError = result.data.data.data;
          this.isRegisterError = true;
          // return this.authError = result.data.data.data;
        } else {
          this.tryingToRegister = false;
          this.isRegisterError = false;
          this.registerSuccess = true;
          this.regSuccess = result.data.data.data;
          localStorage.setItem('username', this.user.name)
          localStorage.setItem('useremail', this.user.useremail)
          //   alert(result.data.data.data);
          //  console.log(result.data.data.data);
        }

      }
    }, async changepassword() {
      this.passerror = false;
      this.passok = false;

      if (this.oldpasswordInput == '') {
        this.passerror = true;
        this.passerrormsg = ' Empty old password field'
        return;
      }

      if (this.newpasswordInput == '') {
        this.passerror = true;
        this.passerrormsg = ' Empty new password field'
        return;
      }
      if (this.confirmpasswordInput == '') {
        this.passerror = true;
        this.passerrormsg = ' Empty confirm new password field'
        return;
      }

      

      if (this.newpasswordInput !== this.confirmpasswordInput) {
        this.passerror = true;
        this.passerrormsg = ' New and Confirm password mismatched!'
        return;
      }



      var result = await axios.post(appConfig.api_url + 'ChangePassword', {
        OldPassword: this.oldpasswordInput,
        NewPassword: this.newpasswordInput,
        Id: localStorage.getItem('userid')

      })

     // alert(JSON.stringify(result.data));
      if (result.data.status == 'success') {
        this.passok = true;
        this.passokmsg = 'Password changed successfully!'
        this.oldpasswordInput = '';
        this.newpasswordInput = '';
        this.confirmpasswordInput = '';

      } else {
        this.passerror = true;
        this.passerrormsg = result.data.data
      }


      // oldpasswordInput: '',
      // newpasswordInput: '',
      // confirmpasswordInput: '',
      // passerror:false,
      // passok: false,
      // passerrormsg:'',
      // passokmsg:'',

    }, async filteruser() {
      this.userslist = [];
      this.getUserData();
    }, async filterpayments() {
      this.paymentslist = [];
      this.getpaymentData();
    }, ChangeStatus(id) {

      this.updateuser_id = id;
      document.getElementById('openupdatemodalbutton').click();


    }, async UpdateStatus() {

      //alert(this.updateuser_id + "====" + this.userstatus)
      await axios.post(appConfig.api_url + 'updateUserStatus', {
        Id: this.updateuser_id,
        Status: this.userstatus
      })


      //alert(JSON.stringify(result.data));
      //console.log(JSON.stringify(result));
      // this.userslist = users;
      // document.getElementById('openupdatemodalbutton').click();
      this.usersok = true;
      this.usersokmsg = 'User status updated'
      this.getUserData();
      document.getElementById('closeupdatemodalbutton').click();

    }, async getpaymentData() {
      //var userid = localStorage.getItem('userid')
      this.usertype = localStorage.getItem('usertype')
      //alert(this.usertype);

      // this.is_admin = true;
      //alert(parseInt(this.currentpage)+ "======" + this.userSearchTerm);
      var result = await axios.post(appConfig.api_url + 'getpayments', {
        filter: '' + this.paymentsSearchTerm,
        page: this.payment_currentpage,
        user_id: localStorage.getItem('userid'),
        usertype: this.usertype
      })

      var payments = result.data.data.user_res;
      this.payment_totalpage = result.data.totalpages;
      this.payment_totalrows = result.data.totalrows;
      // alert(JSON.stringify(result.data));
      console.log(JSON.stringify(payments));
      this.paymentslist = payments;

    }, clickpaymentCallback(pageNum) {
      this.payment_currentpage = parseInt(pageNum) - 1;
      this.getpaymentData();
    },
  },
};
</script>

<template>
  <Layout>

    <form class="needs-validation" @submit.prevent="updateuserprofile" enctype="multipart/form-data">
      <div class="row">

        <!-- <div class="col-xxl-3">
          <div class="card">
            <div class="card-body p-4">
              <div class="text-center">
                <div class="
                  profile-user
                  position-relative
                  d-inline-block
                  mx-auto
                  mb-4
                ">
                  <img :src="imgsrc" class="
                    rounded-circle
                    avatar-xl
                    img-thumbnail
                    user-profile-image
                  " alt="user-profile-image" />
                  <div class="avatar-xs p-0 rounded-circle profile-photo-edit">
                    <input id="profile-img-file-input" ref="file" @change="onselect" accept="image/*" type="file"
                      class="profile-img-file-input" />
                    <label for="profile-img-file-input" class="profile-photo-edit avatar-xs">
                      <span class="avatar-title rounded-circle bg-light text-body">
                        <i class="ri-camera-fill"></i>
                      </span>
                    </label>
                  </div>
                </div>
                <h5 class="fs-16 mb-1">Anna Adame</h5>
                <p class="text-muted mb-0">Lead Designer / Developer</p>
              </div>
            </div>
          </div>
        
        </div> -->
        <!--end col-->

        <div class="col-xxl-12">
          <div class="card ">
            <div class="card-header">
              <ul class="
                nav nav-tabs-custom
                rounded
                card-header-tabs
                border-bottom-0
              " role="tablist">
                <!-- <li class="nav-item">
                  <a class="nav-link active" data-bs-toggle="tab" href="#personalDetails" role="tab">
                    <i class="fas fa-home"></i>
                    Personal Details
                  </a>
                </li> -->
                <li class="nav-item">
                  <a class="nav-link active" data-bs-toggle="tab" href="#changePassword" role="tab">
                    <i class="far fa-user"></i>
                    Change Password
                  </a>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#Subscription" role="tab">
                    <i class="far fa-envelope"></i>
                    My Subscription
                  </a>
                </li>
                <li class="nav-item" v-if="is_admin">
                  <a class="nav-link" data-bs-toggle="tab" href="#experience" role="tab">
                    <i class="far fa-envelope"></i>
                    Users List
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#payments" role="tab">
                    <i class="far fa-envelope"></i>
                    {{is_admin ? 'All Payments': 'My Payments'}}
                  </a>
                </li> -->

              </ul>
            </div>
            <div class="card-body p-4">
              <div class="tab-content">

                <div class="tab-pane active" id="changePassword" role="tabpanel">
                  <b-alert v-model="passok" class="mt-3" variant="success" dismissible>{{passokmsg}}</b-alert>

                  <b-alert v-model="passerror" class="mt-3" variant="danger" dismissible>{{ passerrormsg }}
                  </b-alert>


                  <form class="needs-validation" @submit.prevent="changepassword">
                    <div class="row g-2">
                      <div class="col-lg-4">
                        <div>
                          <label for="oldpasswordInput" class="form-label">Old Password*</label>
                          <input requried v-model="oldpasswordInput" type="password" class="form-control"
                            placeholder="Enter current password" />
                        </div>
                      </div>
                      <!--end col-->
                      <div class="col-lg-4">
                        <div>
                          <label for="newpasswordInput" class="form-label">New Password*</label>
                          <input v-model="newpasswordInput" requried type="password" class="form-control"
                            placeholder="Enter new password" />
                        </div>
                      </div>
                      <!--end col-->
                      <div class="col-lg-4">
                        <div>
                          <label for="confirmpasswordInput" class="form-label">Confirm Password*</label>
                          <input requried v-model="confirmpasswordInput" type="password" class="form-control"
                            placeholder="Confirm password" />
                        </div>
                      </div>
                      <!--end col-->
                      <div class="col-lg-12">
                        <div class="mb-3">
                          <a href="javascript:void(0);" class="link-primary text-decoration-underline">Forgot Password
                            ?</a>
                        </div>
                      </div>
                      <!--end col-->
                      <div class="col-lg-12">
                        <div class="text-end">
                          <button type="submit" class="btn btn-success">
                            Change Password
                          </button>
                        </div>
                      </div>
                      <!--end col-->
                    </div>
                    <!--end row-->
                  </form>
                  <!-- <div class="mt-4 mb-3 border-bottom pb-2">
                  <div class="float-end">
                    <a href="javascript:void(0);" class="link-primary">All Logout</a>
                  </div>
                  <h5 class="card-title">Login History</h5>
                </div>
                <div class="d-flex align-items-center mb-3">
                  <div class="flex-shrink-0 avatar-sm">
                    <div class="avatar-title bg-light text-primary rounded-3 fs-18">
                      <i class="ri-smartphone-line"></i>
                    </div>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <h6>iPhone 12 Pro</h6>
                    <p class="text-muted mb-0">
                      Los Angeles, United States - March 16 at 2:47PM
                    </p>
                  </div>
                  <div>
                    <a href="javascript:void(0);">Logout</a>
                  </div>
                </div> -->

                </div>

                <div class="tab-pane" id="payments" role="tabpanel">

                  <b-alert v-model="usersok" class="mt-3" variant="success" dismissible>{{usersokmsg}}</b-alert>

                  <b-alert v-model="userserror" class="mt-3" variant="danger" dismissible>{{ userserrormsg }}
                  </b-alert>

                  <div class="row">
                    <div class="col-12">
                      <div class="page-title-box d-sm-flex align-items-center justify-content-between">

                        <div class="form-icon">
                          <input v-model="paymentsSearchTerm" type="text" @keyup="filterpayments"
                            placeholder="Search Payments" class="form-control form-control-icon"
                            aria-label="Recipient's username" aria-describedby="button-addon2">
                          <i class=" ri-search-line"></i>
                        </div>

                        <div class="page-title-right">
                          <!-- <button class="btn btn-success " data-bs-toggle="modal" id="createbutton"
          data-bs-target="#createdocumentsmodal">+ Create
          Document</button> -->
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="notification.message" :class="'alert ' + notification.type">
                    {{ notification.message }}
                  </div>
                  <div class="row align-items-start" style="min-height: 6rem;">
                    <div class="table-responsive table-card">
                      <table class="table align-middle table-nowrap mb-0">
                        <thead class="table-light">
                          <tr>
                            <th scope="col">Action</th>
                            <th v-if="is_admin" scope="col">User ID</th>
                            <th v-if="is_admin" scope="col">Name</th>
                            <th v-if="is_admin" scope="col">Email</th>
                            <th scope="col">Package</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Payemnt At</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(payment) of paymentslist" v-bind:key="payment.Id"
                            :style="payment.status == 'Paid' ? 'background-color:#ddfcdd': 'background-color:#ffe5e5' ">
                            <td><a v-if="payment.status == 'Paid'" :href="'/invoices/detail/'+payment.id"
                                target="_blank"><i class="ri-printer-fill"></i></a></td>
                            <td v-if="is_admin">{{payment.Id}}</td>
                            <td v-if="is_admin">{{payment.Name}}</td>
                            <td v-if="is_admin">{{payment.Email}}</td>
                            <td>{{payment.package_name}}</td>
                            <td>{{payment.amount}}</td>
                            <td>{{payment.custome_created_at}}</td>
                            <td
                              :class="payment.status == 'Paid' ? 'badge badge-soft-success fs-11': 'badge badge-soft-danger fs-11' ">
                              {{payment.status}}</td>
                          </tr>

                        </tbody>

                      </table>
                      <!-- end table -->
                    </div>
                    <!-- end table responsive -->
                  </div>

                  <div class="row mt-3">
                    <div class="col-12 mt-3">
                      <div class="page-title-box d-sm-flex align-items-center justify-content-between">

                        <p>Total rows: {{payment_totalrows}}</p>

                        <div class="page-title-right">
                          <paginate v-model="paymentpage" :page-count="payment_totalpage" :margin-pages="2"
                            :click-handler="clickpaymentCallback" :prev-text="'Prev'" :next-text="'Next'"
                            :container-class="'pagination'" :page-class="'page-item'">
                          </paginate>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="tab-pane" id="Subscription" role="tabpanel">
                  <div class="row">
                    <div class="chat-wrapper d-lg-flex mx-n4 mt-n4">
                      <div class="col-md-12">
                        <div class="card mb-0">
                          <div class="card-body border-bottom">

                            <div class="row justify-content-left">

                              <div class="col-lg-12">
                                <div class="card pricing-box">
                                  <div class="card-body p-4 m-2">
                                    <div class="d-flex align-items-center">
                                      <div class="flex-grow-1">
                                        <h3 class="mb-1"><b>Current Package:</b> {{db_packagename}}</h3>
                                        <h5 class="mb-1"><b>Expiry:</b> {{db_validity}}</h5>
                                      </div>

                                    </div>

                                    <hr class="my-4 text-muted">
                                    <div>
                                      <ul class="list-unstyled text-muted vstack gap-3">
                                        <li>
                                          <div class="d-flex">
                                            <div class="flex-shrink-0 text-success me-1">
                                              <i class="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                            </div>
                                            <div class="flex-grow-1">
                                              <b>Remaining Text Generations:</b> <br> 
                                              <!-- {{db_writer_remaining_words}}Words <br></br> -->
                                                {{db_writer_remaining_credits}} Credits
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="d-flex">
                                            <div class="flex-shrink-0 text-success me-1">
                                              <i class="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                            </div>
                                            <div class="flex-grow-1">
                                              <b>Remaining Narration:</b> <br> {{db_narrations}} Charactors
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="d-flex">
                                            <div class="flex-shrink-0 text-success me-1">
                                              <i class="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                            </div>
                                            <div class="flex-grow-1">
                                              <b>Remaining PDF Upload :</b> <br> {{db_pdfupload}} Documents
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="d-flex">
                                            <div class="flex-shrink-0 text-success me-1">
                                              <i class="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                            </div>
                                            <div class="flex-grow-1">
                                              <b>Remaining Transcription :</b> <br> {{db_Transcription}}
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="d-flex">
                                            <div class="flex-shrink-0 text-success me-1">
                                              <i class="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                            </div>
                                            <div class="flex-grow-1">
                                              <b>Remaining Image Generation :</b> <br> {{db_ImageGeneration}}
                                            </div>
                                          </div>
                                        </li>

                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                            <!--end row-->


                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>

                <div class="tab-pane" id="experience" role="tabpanel">

                  <b-alert v-model="usersok" class="mt-3" variant="success" dismissible>{{usersokmsg}}</b-alert>

                  <b-alert v-model="userserror" class="mt-3" variant="danger" dismissible>{{ userserrormsg }}
                  </b-alert>

                  <div class="row">
                    <div class="col-12">
                      <div class="page-title-box d-sm-flex align-items-center justify-content-between">

                        <div class="form-icon">
                          <input v-model="userSearchTerm" type="text" @keyup="filteruser"
                            placeholder="Search User by name, email or status" class="form-control form-control-icon"
                            aria-label="Recipient's username" aria-describedby="button-addon2">
                          <i class=" ri-search-line"></i>
                        </div>

                        <div class="page-title-right">
                          <!-- <button class="btn btn-success " data-bs-toggle="modal" id="createbutton"
                            data-bs-target="#createdocumentsmodal">+ Create
                            Document</button> -->
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="notification.message" :class="'alert ' + notification.type">
                    {{ notification.message }}
                  </div>
                  <div class="row align-items-start" style="min-height: 6rem;">
                    <div class="table-responsive table-card">
                      <table class="table align-middle table-nowrap mb-0">
                        <thead class="table-light">
                          <tr>
                            <th scope="col">Action</th>

                            <th scope="col">ID</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Package</th>
                            <th scope="col">Rem. Gen. Words/Char/credit</th>
                            <th scope="col">Rem. Narr.</th>
                            <th scope="col">Rem. PDF</th>
                            <!-- <th scope="col">Rem. Transc.</th>
                            <th scope="col">Rem. IMG Gen.</th> -->
                            <th scope="col">Expiry</th>
                            <th scope="col">Created At</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(user) of userslist" v-bind:key="user.Id"
                            :style="user.Status == 'Active' ? 'background-color:#ddfcdd': 'background-color:#ffe5e5' ">
                            <td><a @click="ChangeStatus(user.Id)"><i class="ri-edit-2-fill"></i></a></td>
                            <td>{{user.Id}}</td>
                            <td>{{user.Name}}</td>
                            <td>{{user.Email}}</td>
                            <td>{{user.current_subscribed_package}}</td>
                            <td>{{user.writer_remaining_words}} / {{user.writer_remaining_charactors}} /
                              {{user.writer_remaining_credits}}</td>
                            <td>{{user.narrations}}</td>
                            <td>{{user.pdfupload}}</td>
                            <!-- <td>{{user.Transcription}}</td>
                            <td>{{user.ImageGeneration}}</td> -->
                            <td>{{user.customevalidity}}</td>

                            <td>{{user.CreatedAt}}</td>
                            <td>{{user.Status}}</td>

                          </tr>

                        </tbody>

                      </table>
                      <!-- end table -->
                    </div>
                    <!-- end table responsive -->
                  </div>

                  <div class="row mt-3">
                    <div class="col-12">
                      <div class="page-title-box d-sm-flex align-items-center justify-content-between">

                        <p>Total rows: {{totalrows}}</p>

                        <div class="page-title-right">
                          <paginate v-model="page" :page-count="totalpage" :margin-pages="2"
                            :click-handler="clickCallback" :prev-text="'Prev'" :next-text="'Next'"
                            :container-class="'pagination'" :page-class="'page-item'">
                          </paginate>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="tab-pane " id="personalDetails" role="tabpanel">

                  <b-alert v-model="registerSuccess" class="mt-3" variant="success" dismissible>{{regSuccess}}</b-alert>

                  <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible>{{ regError }}
                  </b-alert>

                  <div v-if="notification.message" :class="'alert ' + notification.type">
                    {{ notification.message }}
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="firstnameInput" class="form-label">Name</label>
                        <input type="text" class="form-control" v-model="user.name" :class="{
                          'is-invalid': submitted && v$.user.name.$error,
                        }" id="name" placeholder="Enter your firstname" required />
                        <div v-if="submitted && v$.user.name.$error" class="invalid-feedback">
                          <span v-if="v$.user.name.required.$message">{{
                          v$.user.name.required.$message
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <!--end col-->

                    <!--end col-->
                    <!-- <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="phonenumberInput" class="form-label">Phone Number</label>
                        <input type="text" class="form-control" id="mobile" v-model="user.mobile" :class="{
                          'is-invalid': submitted && v$.user.mobile.$error,
                        }" placeholder="Enter your phone number" />
                      </div>
                    </div> -->
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="emailInput" class="form-label">Email Address</label>
                        <input type="email" class="form-control" id="useremail" v-model="user.useremail" :class="{
                          'is-invalid': submitted && v$.user.useremail.$error,
                        }" placeholder="Enter your email" required />
                        <div v-if="submitted && v$.user.useremail.$error" class="invalid-feedback">
                          <span v-if="v$.user.useremail.required.$message">{{
                          v$.user.useremail.required.$message
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <!-- <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="JoiningdatInput" class="form-label">Joining Date</label>

                        <flat-pickr v-model="date" class="form-control"></flat-pickr>

                      </div>
                    </div> -->
                    <!--end col-->
                    <!-- <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="skillsInput" class="form-label">Skills</label>
                        <Multiselect v-model="value" mode="tags" :close-on-select="false" :searchable="true"
                          :create-option="true" :options="[
                            { value: 'illustrator', label: 'Illustrator' },
                            { value: 'photoshop', label: 'Photoshop' },
                            { value: 'css', label: 'CSS' },
                            { value: 'html', label: 'HTML' },
                            { value: 'javascript', label: 'Javascript' },
                            { value: 'python', label: 'Python' },
                            { value: 'php', label: 'PHP' },
                          ]" />
                      </div>
                    </div> -->
                    <!--end col-->
                    <!-- <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="designationInput" class="form-label">Designation</label>
                        <input type="text" class="form-control" id="designationInput" placeholder="Designation"
                          value="Lead Designer / Developer" />
                      </div>
                    </div> -->
                    <!--end col-->
                    <!-- <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="websiteInput1" class="form-label">Website</label>
                        <input type="text" class="form-control" id="websiteInput1" placeholder="www.example.com"
                          value="www.velzon.com" />
                      </div>
                    </div> -->
                    <!--end col-->
                    <!-- <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="cityInput" class="form-label">City</label>
                        <input type="text" class="form-control" id="city" v-model="user.city" :class="{
                          'is-invalid': submitted && v$.user.city.$error,
                        }" placeholder="City" />
                      </div>
                    </div> -->
                    <!--end col-->
                    <!-- <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="countryInput" class="form-label">Country</label>
                        <input type="text" class="form-control" v-model="user.country" :class="{
                          'is-invalid': submitted && v$.user.country.$error,
                        }" id="country" placeholder="" />
                      </div>
                    </div> -->
                    <!--end col-->
                    <!-- <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="zipcodeInput" class="form-label">Zip Code</label>
                        <input type="text" class="form-control" id="zipcode" placeholder="Enter zipcode"
                          v-model="user.zipcode" :class="{
                            'is-invalid': submitted && v$.user.zipcode.$error,
                          }" />
                      </div>
                    </div> -->
                    <!--end col-->
                    <!-- <div class="col-lg-12">
                      <div class="mb-3 pb-2">
                        <label for="exampleFormControlTextarea" class="form-label">Description</label>
                        <textarea class="form-control" id="description" placeholder="Enter your description" rows="3"
                          v-model="user.description" :class="{
                            'is-invalid': submitted && v$.user.description.$error,
                          }"></textarea>
                      </div>
                    </div> -->
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="hstack gap-2 justify-content-end">
                        <button type="submit" class="btn btn-primary">
                          Updates
                        </button>

                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->

                </div>
                <!--end tab-pane-->

              

                <!--end tab-pane-->

              </div>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
    </form>
    <div class="row">

    </div>

    <!-- start of create document edit modal -->
    <a data-bs-toggle="modal" id="openupdatemodalbutton" data-bs-target="#updatecreatedocumentsmodal"></a>
    <div class="modal fade" id="updatecreatedocumentsmodal" tabindex="-1" aria-labelledby="exampleModalgridLabel">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalheader">Update User Status</h5>
            <button type="button" id="closeupdatemodalbutton" class="btn-close" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="UpdateStatus" id="updateDocumentform">
              <div class="row g-3">
                <div class="col-xxl-12">
                  <div>
                    <label for="firstName" class="form-label">Select Status</label>
                    <select v-model="userstatus" class="form-select mb-3" aria-label="Default select example">
                      <option selected value="Active">Active</option>
                      <option value="Disabled">Disabled</option>
                    </select>

                    <input type="hidden" v-model="updateuser_id">

                  </div>
                </div>
                <!--end col-->

                <!--end col-->
                <div class="col-lg-12">
                  <div class="hstack gap-2 justify-content-end">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Update</button>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </form>
          </div>
        </div>
      </div>
    </div>

    <!--end row-->
  </Layout>
</template>